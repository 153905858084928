<!--
- bgText: text - not used
- backgroundImage: image - not used
-->

<template>
	<div class="cs-logo-reel cs-block" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<div class="header-content">
					<h2 class="cs-title" v-if="data.title">{{ data.title }}</h2>
					<h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
					<span class="cs-text" v-if="data.text" v-html="data.text"></span>
				</div>
				<Slider class="slider" :data="imageSlides" v-slot="item">
					<CSButton :data="item.slide.link">
						<img :src="item.slide.icon_image" class="slider-image" />
						<h4 v-if="item.slide.title">
							{{ item.slide.title }}
						</h4>
						<span class="item-text cs-text" v-if="item.slide.text" v-html="item.slide.text" />
					</CSButton>
				</Slider>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "IconSlider",
	components: {
		Slider: () => import("../blocks_components/Slider.vue"),
        CSButton: () => import("../components/CSButton.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			slickOptions: {
				slidesToShow: 5,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 5,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 5,
						},
					},
					{
						breakpoint: 964,
						settings: {
							slidesToShow: 4,
						},
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 3,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
						},
					},
				],
			},
		}
	},
	methods: {
		next() {
			this.$refs.slick.next()
		},

		prev() {
			this.$refs.slick.prev()
		},
	},
	computed: computed('LogoReel')
}
</script>

<style scoped lang="scss">
@import "../styles/main.scss";
.cs-logo-reel {
	position: relative;

	.header-content {
		margin-bottom: 10px;
		text-align: center;
	}

	.slider {
		::v-deep .cs-button {
            cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			color: inherit;
		}
		.slider-image {
			height: 100px;
			width: 100%;
			object-fit: cover;
		}

		h4,
		span {
			text-align: center;
		}
        h4 {
			@include small-title;
            margin-top: 15px;
		}
		span.item-text {
			@include text;
            margin-top: 15px;
            display: block;
		}
	}
    &.cs-style-dark ::v-deep {
        h4 {
            color: #fff !important;
        }
        span.item-text {
            color: #fff !important;
            ::v-deep {
                color: #fff !important
            }
        }
    }
}
</style>
